<template>
  <v-container id="stores" class="py-0">
    <v-row>
      <v-col>
        <h2 class="align-self-end page-title">
          <v-btn
            icon
            class="mr-2"
            color="grey"
            :ripple="false"
            @click="$router.push('/settings/companies')"
            v-if="authEsa"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span v-if="authEsa">{{ orgName }}</span>
          <span v-else>{{ $t(`settings.title.manageCompany`) }}</span>
        </h2>
      </v-col>
      <v-col cols="12" class="pt-0">
        <SettingsNavigation />
      </v-col>
    </v-row>
    <v-row
      class="flex-column flex-row flex-sm-row-reverse"
      :class="{ 'justify__flex-end': !authEsa }"
    >
      <v-col
        cols="12"
        sm="6"
        class="d-flex justify-end"
        v-if="authEsa && isAdmin"
      >
        <v-btn
          id="addSalon"
          elevation="1"
          color="blue-grey lighten-5"
          min-width="265px"
          height="39px"
          @click="$emit('mode', [`store/add${queryOrgId}`, true])"
        >
          {{ $t("action.addSalon") }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <BaseSearch :label="`searchByTable`" @search="setSearch" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :header-props="{ sortByText: sortByText }"
          :items="stores"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
          :loading="loading"
          class="elevation-1"
          locale="uk"
        >
          <v-progress-linear
            v-show="loading"
            color="privat"
            indeterminate
          ></v-progress-linear>
          <template v-slot:loading>
            <div class="d-flex flex-column justify-center align-center">
              <span class="text-subtitle-1 pt-7 pb-5">
                {{ $t("load") }}
              </span>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="d-flex flex-column justify-center align-center mb-6">
              <span class="text-subtitle-1 pt-7 pb-5">
                {{ $t("settings.notStores") }}
              </span>
            </div>
          </template>
          <template v-slot:item.id="{ item }">
            <span>{{ item.id }}</span>
          </template>
          <template v-slot:item.name="{ item }">
            <span>{{ item.name }}</span>
          </template>
          <template v-slot:item.address="{ item }">
            <span>{{ item.address }}</span>
          </template>
          <template v-slot:item._sortManager="{ item }">
            <span class="d-block">{{ item.managerName }}</span>
            <span class="grey--text text--darken-1" v-if="authEsa">
              {{ item.managerLdap }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }" v-if="authEsa">
            <v-menu bottom left transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="openAction"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <span>{{ $t("action.openAction") }}</span>
                  </v-tooltip>
                </v-btn>
              </template>

              <v-list>
                <v-list-item id="edit" @click="clickActions(['EDIT', item])">
                  <v-list-item-title class="d-flex align-center">
                    <v-icon medium class="mr-2" color="blue darken-3">
                      mdi-account-edit
                    </v-icon>
                    {{ $t("action.edit") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="lengthStores && lengthStores > 10">
      <v-col cols="12" sm="10" class="align-self-center">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
          color="privat"
        ></v-pagination>
      </v-col>
      <v-col cols="4" sm="2" offset="8" offset-sm="0">
        <v-select
          :label="$i18n.t('label.onPage')"
          :value="itemsPerPage"
          :items="prePages"
          @change="itemsPerPage = parseInt($event, 10)"
        ></v-select>
      </v-col>
    </v-row>
    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </v-container>
</template>

<script>
import SettingsNavigation from "@/components/settings/SettingsNavigation";
import BaseSearch from "@/components/BaseSearch";
// import SettingsFilteredSelect from "@/components/settings/SettingsFilteredSelect";
import showSnack from "@/mixins/showSnack.mixins";
import { mapGetters } from "vuex";
import errorHandler from "@/utils/errorHandler";

export default {
  name: "Stores",
  mixins: [showSnack],
  props: {
    options: Object,
    company: Object,
    updateStores: Boolean
  },
  data() {
    return {
      loading: true,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      prePages: [10, 20, 50, 100, 200],
      stores: []
    };
  },
  components: {
    SettingsNavigation,
    BaseSearch
    // SettingsFilteredSelect
  },
  computed: {
    ...mapGetters([
      "getDefSettings",
      "getPointOfEntry",
      "getStores",
      "getUser"
    ]),
    sortByText() {
      return this.$i18n.t("label.sortByText");
    },
    headers() {
      let headers = [
        // {
        //   text: this.$i18n.t("settings.columns.id"),
        //   value: "id",
        //   align: "start",
        //   width: "75px"
        // },
        {
          text: this.$i18n.t("settings.columns.name"),
          value: "name"
        },
        { text: this.$i18n.t("settings.columns.address"), value: "address" },
        {
          text: this.$i18n.t("settings.columns.manager"),
          value: "_sortManager"
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
          width: "75px"
        }
      ];
      if (!this.authEsa) {
        return headers.filter(el => el.value !== "actions");
      }
      return headers;
    },
    lengthStores() {
      return this.stores.length;
    },
    allStores() {
      return this.getDefSettings?.availableStores || [];
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    isAdmin() {
      return this.getUser?.userGrants?.includes("ADMINISTRATOR");
    },
    orgName() {
      let orgName = this.$i18n.t("settings.title.manageCompany");

      if (this.authEsa) {
        orgName = this.company?.name || this.selectedCompany?.name;
      }

      return orgName;
    },
    selectedCompany() {
      return this.options.companies.find(
        el => el.id === +this.$route.query.orgId
      );
    },
    queryOrgId() {
      let query = "";

      if (this.authEsa) {
        query = `?orgId=${this.$route.query.orgId}`;
      }

      return query;
    }
  },
  methods: {
    setSearch(data) {
      this.search = data;
    },
    getAllStores() {
      this.loading = true;
      this.timeout = setTimeout(() => {
        this.stores = this.allStores;
        this.loading = false;
      }, 500);
    },
    clickActions(data) {
      let [action, item] = data;

      switch (action) {
        case "DELETE":
          this.$emit("event", ["removeStore", item]);
          break;
        case "EDIT":
          this.$emit("event", ["editStore", item]);
          break;
      }
    },
    getStoresCompany() {
      let orgId = this.company?.id || this.$route.query.orgId;

      this.loading = true;
      this.$store
        .dispatch("getStoresCompany", { orgId: orgId })
        .then(() => {
          this.stores = this.getStores;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "GET_DATA");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getStoresCompany");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    updateStores() {
      if (this.authEsa) {
        this.getStoresCompany();
      } else {
        this.getAllStores();
      }
    }
  },
  mounted() {
    if (this.authEsa) {
      this.getStoresCompany();
    } else {
      this.getAllStores();
    }
  },
  created() {
    this.$emit("mode", []);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  }
};
</script>

<style scoped lang="scss">
.v-bottom-navigation {
  justify-content: start;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
}
.justify__flex-end {
  justify-content: flex-end;
}
#addSalon {
  @media (max-width: 600px) {
    width: 100%;
  }
}
</style>
